import useMobile from "../../hooks/useMobile";
import Card from "@components/information/Card";
import { useUserContext } from "../../utils/UserProvider";
import React, { useState } from "react";
import { IIronman } from "../../interfaces/IIronman";
import StatsBar from "@components/information/StatsBar";
import IStats = IIronman.GetUserInformation.Stats;
import tuentiSemanal from "../../../public/assets/icons/semanal.png";
import tuentiMensual from "../../../public/assets/icons/mensual.png";
import tuentiTrimestral from "../../../public/assets/icons/trimestral.png";
import Image, { StaticImageData } from "next/image";

const logos = [
  {
    value: "semanal",
    image: tuentiSemanal,
  },
  {
    value: "mensual",
    image: tuentiMensual,
  },
  {
    value: "trimestral",
    image: tuentiTrimestral,
  },
];

const Fulfillment = () => {
  const { user } = useUserContext();
  const [infoStats, setInfoStats] = useState<IStats>();
  const [imagesLogo, setImagesLogo] = useState<StaticImageData>({
    src: "",
    height: 0,
    width: 0,
  });
  const { mobile } = useMobile();

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const userInfo: IStats = user?.categories?.find(
      (category: IStats) => category.tipo === event.target.value,
    );
    setInfoStats(userInfo);

    const logoToShow = logos.find(
      (logo) => logo.value === event.target.value.toLowerCase(),
    ).image;

    setImagesLogo(logoToShow);
  };

  return (
    <div className="d-flex flex-wrap justify-content-center">
      <h2 className="pb-3 display-6">¡Hola {user.vendedor}!</h2>
      <div className="col-12 d-flex justify-content-center">
        <select
          defaultValue=""
          className={`form-select shadow-none ${mobile ? "w-75" : "w-25"}`}
          onChange={handleChange}
        >
          <option value="" disabled>
            Seleccione una opción...
          </option>
          <option value="SEMANAL">Semanal</option>
          <option value="MENSUAL">Mensual</option>
          <option value="TRIMESTRAL">Trimestral</option>
        </select>
      </div>
      {infoStats ? (
        <>
          <div className="col-11 col-md-7 pt-5 d-flex flex-wrap gap-3 justify-content-center">
            <Card
              className="col-12 col-md-5"
              label="¿Ganador?"
              ranking={infoStats.ganador}
            />
          </div>
          <div className="col-8">
            <hr />
          </div>
          <div className="col-10 col-md-8 d-flex flex-wrap justify-content-center gap-5 gap-md-0 py-5">
            <div className="col-12 col-md-6 d-flex justify-content-center">
              <Image
                className="pe-auto"
                src={imagesLogo.src}
                alt="Ironman Ventas"
                width={300}
                height={300}
              />
            </div>
            <div className="col-12 col-md-6 flex-wrap align-content-center gap-2">
              <StatsBar
                className="w-100"
                label="Cumplimiento Ft"
                value={+infoStats.cumplimiento_ft}
                total={300}
              />
              <StatsBar
                className="w-100"
                label="Cumplimiento Chip 7"
                value={+infoStats.cumplimiento_chip_7}
                total={300}
              />
              <StatsBar
                className="w-100"
                label="Cumplimiento PortalB"
                value={+infoStats.cumplimiento_portalb}
                total={300}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="alert alert-warning mt-4" role="alert">
          No hay datos
        </div>
      )}
    </div>
  );
};
export default Fulfillment;
