import { ITable } from "../../interfaces/ITable";
import { IIronman } from "../../interfaces/IIronman";
import IExecutiveRank = IIronman.GetUserInformation.ExecutiveRank;

interface IProps<T> {
  data?: T[];
  dataProps?: ITable<T>[];
  classNameTable?: string;
  classNameHeader?: string;
}
const Table = ({
  dataProps,
  data,
  classNameTable,
  classNameHeader,
}: IProps<IExecutiveRank>) => {
  return (
    <>
      <table
        className={
          "table border table-striped table-borderless " + classNameTable
        }
      >
        <thead className={classNameHeader}>
          <tr>
            {dataProps?.map((dataTable, index) => (
              <th className={dataTable.className} key={index} scope="col">
                {dataTable.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((value, index) => (
            <tr key={index}>
              {dataProps?.map((dataTable, key) => (
                <td className={dataTable.className} key={key}>
                  {value[dataTable.attribute]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {!data && (
        <div className="w-100 d-flex justify-content-center">
          <div
            className="alert alert-warning text-center col-8 col-md-6 mt-4"
            role="alert"
          >
            No hay datos
          </div>
        </div>
      )}
    </>
  );
};
export default Table;
