import axios from "axios";

export const generateCaptcha = async (key = process.env.RECAPTCH_SITE_KEY) => {
  const grecaptcha = (window as any).grecaptcha;
  let captchaToken = "";
  grecaptcha?.ready(() => {
    grecaptcha?.execute(key, { action: "submit" })
      .then((token: any) => {
        captchaToken = token;
      })
      .catch((error: any) => {
        console.error("Error al generar el captcha", error);
      });
  });
  while (captchaToken === "") {
    await new Promise((r) => setTimeout(r, 100));
  }
  return captchaToken;
};

export const validateCaptcha = async (token, secret = process.env.RECAPTCH_SECRET_KEY) => {
  const urlCaptcha: string =
    "https://www.google.com/recaptcha/api/siteverify?secret=" +
    secret +
    "&response=" +
    token;
  try {
    return axios.post(`${urlCaptcha}`);

  } catch (error) {
    throw new Error("Api Google no responde | verifyReCaptcha");
  }
};
