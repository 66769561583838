import React, { FC } from "react";
const variants = [
  {
    mainColor: "bg-light-blue",
    secondaryColor: "bg-light-green",
  },
  {
    mainColor: "bg-light-orange",
    secondaryColor: "bg-light-yellow",
  },
];
interface IProps {
  className?: string;
  variant?: 0 | 1;
  label?: string;
  ranking?: string;
}
const Card: FC<IProps> = ({ className, variant = 0, label, ranking }) => {
  return (
    <div className={"d-flex " + className}>
      <div
        className={
          "rounded-start-4 h6 col-7 p-2 justify-content-center d-flex align-items-center " +
          variants[variant].mainColor
        }
      >
        {label}
      </div>
      <div
        className={
          "rounded-end-4 col-5 h1 p-2 justify-content-center d-flex align-items-center " +
          variants[variant].secondaryColor
        }
      >
        {ranking}
      </div>
    </div>
  );
};
export default Card;
