import useNumberTransition from "../../hooks/useNumberTransition";
import { FC } from "react";
interface IProps {
  value?: number;
  total?: number;
  className?: string;
  label?: string;
}
const StatsBar: FC<IProps> = ({ value = 100, total = 1, className, label }) => {
  const { count } = useNumberTransition(value);
  return (
    <>
      <div className={"gap-2 d-flex flex-column " + className}>
        <span className="h5">{label}</span>
        <div className="d-flex gap-4">
          <div className="progress col-10 m-1">
            <div
              className="progress-bar bg-pitaya"
              role="progressbar"
              style={{ width: `${(count / total) * 100}%` }}
            ></div>
          </div>
          <span className="col-2">{count}%</span>
        </div>
      </div>
    </>
  );
};
export default StatsBar;
