import { ITable } from "../interfaces/ITable";
import IExecutiveRank = IIronman.GetUserInformation.ExecutiveRank;
import { IIronman } from "../interfaces/IIronman";
export const executiveTable: ITable<IExecutiveRank>[] = [
  {
    title: "Ganador",
    attribute: "ganador",
    className: "text-start",
  },
  {
    title: "Vendedor",
    attribute: "vendedor",
    className: "text-start",
  },
  {
    title: "Distribuidor",
    attribute: "distribuidor",
    className: "text-start",
  },
];
