import { ITabs } from "../interfaces/ITabs";
import Fulfillment from "@components/tabsContent/Fulfillment";
import Rankings from "@components/tabsContent/Rankings";

export const tabs: ITabs[] = [
  {
    label: "Mi cumplimiento",
    children: <Fulfillment />,
  },
  {
    label: "Rankings",
    children: <Rankings />,
  },
];
