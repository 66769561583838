import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Navigation, Pagination } from "swiper";
import Image from "next/image";

import logo from "../../public/assets/icons/logo-ironman.png";
import { useState } from "react";
import useMobile from "../hooks/useMobile";
import { useFormik } from "formik";
import { IIronman } from "../interfaces/IIronman";
import { object, string } from "yup";
import { useUserContext } from "../utils/UserProvider";
import getUser from "@components/api/fetch";
import { generateCaptcha } from '@components/api/ReCaptcha';

const validationScheme = object({
  cedula: string()
    .required("Ingrese una Cédula")
    .max(10, "Solo se permite 10 caracteres."),
});

const Form = () => {
  const { dispatch } = useUserContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorApi, setErrorApi] = useState<string>();
  const { mobile } = useMobile(768);
  const { handleSubmit, values, errors, handleChange } =
    useFormik<IIronman.GetUserInformation.Request>({
      initialValues: {
        cedula: "",
      },
      onSubmit: async (values) => {
        const token = await generateCaptcha();
        setLoading(true);
        const response = await getUser(values.cedula, token);
        setLoading(false);
        if (!response?.data?.user) {
          setErrorApi(response.data.message);
          return;
        }
        dispatch &&
          dispatch({
            type: "USER_FOUNDED",
            value: response.data.user,
          });
      },
      validationSchema: validationScheme,
    });
  return (
    <div className="d-flex flex-wrap flex-column flex-md-row fade-in">
      <div className={`h-auto ${!mobile ? "w-50" : "w-100"}`}>
        <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination, A11y]}
          slidesPerView={1}
          navigation
          autoplay
          loop
          pagination={{ clickable: true }}
        >
          <SwiperSlide>
            <Image
              src="https://statics-tuenti.s3.us-east-2.amazonaws.com/olimpiadas/Banner-izq.png"
              alt="banner1"
              className="w-100 h-auto"
              priority
              width={1000}
              height={900}
              layout="responsive"
            />
          </SwiperSlide>
        </Swiper>
      </div>
      <div
        className={`h-auto d-flex pt-5 pt-md-0 ${
          !mobile && "w-50"
        } justify-content-center`}
      >
        <div className="d-flex flex-column justify-content-center col-6">
          <div className="d-flex justify-content-center pb-5 mb-5">
            <Image
              src={logo}
              alt="Olimpiadas Ventas"
              width={300}
              height={300}
            />
          </div>
          <form onSubmit={handleSubmit}>
            <label htmlFor="cedula" className=" ">
              Cédula / CI <span className="text-danger">*</span>
            </label>
            <input
              name="cedula"
              type="text"
              className={`form-control shadow-none ${
                errors.cedula || (errorApi && "is-invalid")
              }`}
              placeholder="1700000000"
              onChange={handleChange}
              value={values.cedula}
              maxLength={10}
            />
            <div className="invalid-feedback">{errors?.cedula || errorApi}</div>
            <div className="d-flex justify-content-center m-3 ">
              <button
                type="submit"
                className="btn btn-primary btn-lg w-100 rounded-pill"
                disabled={loading}
              >
                {!loading ? (
                  "Ingresar"
                ) : (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Buscando...
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Form;
