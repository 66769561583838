import logo from "../../public/assets/icons/logo-ironman.png";
import Image from "next/image";
import React, { useState } from "react";
import { tabs } from "../data/tabs";
import logout from "../../public/assets/icons/logo-logout.svg";
import { useUserContext } from "../utils/UserProvider";
import styles from "../styles/Home.module.css";
const Stats = () => {
  const { dispatch } = useUserContext();
  const [selected, setSelected] = useState<string>("0");

  const handleChange = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSelected(event.currentTarget.value);
  };
  const logoutOnClick = () => {
    dispatch &&
      dispatch({
        type: "USER_REMOVED",
      });
  };
  return (
    <>
      <div className="btn-logout">
        <Image
          src={logout}
          alt="Retroceder"
          width={60}
          onClick={logoutOnClick}
        />
      </div>

      <div className="d-flex align-content-center flex-column py-5 fade-in">
        <div className="d-flex justify-content-center pt-3">
          <Image
            className="pe-auto"
            src={logo}
            alt="Ironman Ventas"
            width={300}
            height={300}
          />
        </div>
        <div className="d-flex justify-content-center gap-3 pb-4">
          {tabs.map((tab, index) => (
            <button
              key={index}
              value={index}
              className={`btn btn-lg rounded-pill ${
                selected === index.toString()
                  ? styles.btnFuchsia
                  : styles.btnOutlineFuchsia
              }`}
              onClick={handleChange}
            >
              {tab.label}
            </button>
          ))}
        </div>
        {tabs[+selected].children}
      </div>
    </>
  );
};
export default Stats;
