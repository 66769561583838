import axios from "axios";

const getUser = async (na: string, token: string) => {
  try {
    return axios.get(`/api/user/${na}`,{
      headers:{
        'Authorization': `${token}`
      }
    });
  } catch (e) {
    console.log(e);
    throw Error(e);
  }
};
export default getUser;
