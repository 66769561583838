import type { NextPage } from "next";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Form from "@components/Form";
import Stats from "@components/Stats";
import { useUserContext } from "../utils/UserProvider";

const Home: NextPage = () => {
  const { user } = useUserContext();
  return !user ? <Form /> : <Stats />;
};

export default Home;
