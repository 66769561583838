import { useUserContext } from "../../utils/UserProvider";
import useMobile from "../../hooks/useMobile";
import { useState } from "react";
import IStats = IIronman.GetUserInformation.Stats;
import { IIronman } from "../../interfaces/IIronman";
import Table from "@components/information/Table";
import { executiveTable } from "../../data/tables";
const Rankings = () => {
  const { user } = useUserContext();
  const [infoStats, setInfoStats] = useState<IStats>();
  const { mobile } = useMobile();
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setInfoStats(
      user?.categories?.find(
        (category: IStats) => category.tipo === event.target.value,
      ),
    );
  };

  return (
    <div className="d-flex flex-wrap justify-content-center gap-3">
      <div className="col-12 d-flex justify-content-center">
        <select
          defaultValue=""
          className={`form-select shadow-none ${mobile ? "w-75" : "w-25"}`}
          onChange={handleChange}
        >
          <option value="" disabled>
            Seleccione una opción...
          </option>
          <option value="SEMANAL">Semanal</option>
          <option value="MENSUAL">Mensual</option>
          <option value="TRIMESTRAL">Trimestral</option>
        </select>
      </div>
      <div className="col-12 px-2 px-md-0 col-md-8 text-center">
        <span className="h4 pb-2 ">Los mejores 20 vendedores</span>
        <Table
          classNameTable="mt-4"
          classNameHeader="bg-pitaya text-white"
          data={infoStats?.executive_top}
          dataProps={executiveTable}
        />
      </div>
    </div>
  );
};
export default Rankings;
