import { useEffect, useState } from "react";

const useNumberTransition = (value: number) => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (!value) return;
    if (count === value) return;
    let totalMilSecDur = 0;
    let incrementTime = (totalMilSecDur / value) * 1000;
    let timer = setInterval(() => {
      if (count < value) setCount((val) => val + 1);
      if (count > value) setCount((val) => val - 1);
      clearInterval(timer);
    }, incrementTime);
  }, [value, count]);
  return {
    count,
  };
};
export default useNumberTransition;
